@import (less) "mixins.less";

ul#menu-mobile-navigation{
    width:100%;
    background:@aquaDark;
    padding:0;
    margin:0;
    display:none;
    >li{
        display:block;
        .centered();
        overflow:hidden;
        ul.sub-menu{
        	height:0;
        	>li{
        	background:@grayLight;
        	>a{
        		font-size:15px;
        		color:@aquaMed;
        		border-bottom:1px solid #fff;
        		opacity:0;
        		}
        	}
        	}
        a{
            display:block;
            width:100%;
            padding:15px;
            .uppercase();
            .montserrat(18px);
            color:#fff;
            border-bottom:1px solid @aquaMed;
            &:hover{
                }
            }
        }
    }
    
a#menu-toggle{
    color:#fff;
    font-size:24px;
    position:absolute;
    top:10px;
    right:10px;
    display:none;
    z-index:500;
    &.active{
        }
    }

@media only screen 
and (max-width : 1200px) {
    a#map{
    	top:-220px;
    	svg{
    		
    		}
    	}
    }
    
@media only screen 
and (min-width : 1700px) {
    a#map{
    	top:-300px;
    	svg{
    		
    		}
    	}
    }
    
@media only screen 
and (max-width : 1000px) {
    a#map{
    	top:-190px;
    	>div{
    		font-size:20px;
    		padding-left:240px;
    		}
    	svg{
    		width:290px;
    		}
    	}
    }

@media only screen 
and (max-width : 900px) {
    ul#menu-main-navigation{
        li{
            a{font-size:12px;}
            }
        }
    }
    
@media only screen 
and (max-width : 800px) {
   a#menu-toggle{
       display:inline-block;
       }
    #menu-main-navigation{display:none;}
   
    }
 
@media only screen 
and (max-width : 768px) {
	#main-header, #main-header.active{
		position:relative;
		background:@aqua;
		border-bottom:4px solid #fff;
		.centered();
		padding:10px 0 10px 0;
		height:auto;
		min-height:0;
		#menu-top-navigation{
			display:none;
			}
		#logo-svg{
			.inline-block();
			max-width:220px;
			height:auto;
			}
		#menu-social-navigation{
			.centered();
			width:100%;
			margin:15px 0 0 0;
			padding:0;
			li{
				color:@yellow;
				font-size:24px;
				}
			}
		}
	#video-banner{
		height:auto;
		padding:40px 0 40px 0;
		
		}
	#banner-slides{
		button{display: none!important;}
		}
	.banner-content{
		transform:translateY(0);
		h1{
			font-size:40px;
			}
		}
	.blog-item.homepage{
		margin-bottom:30px;
		}
	.blog-item.subpage{
		padding:0!important;
		}
	a#map{
		display:none;
		}
	#banner{
		&.subpage{
			height:auto;
			min-height: 0;
			padding:30px 10px 30px 10px;
			h1{
				font-size:40px;
				
				}
			}
		}
	.subnav-bar{
		display:none;
		padding:15px 0 15px 0;
		ul.subnav-menu{
		li{
			.centered();
			display:block;
			margin:0;
			a{
				padding:5px 10px 5px 10px;
				}
			}
		}
	}
	#main-content{
		padding-right:10px;
		}
	#sidebar{
		border:none;
		padding-left:10px;
		}
	.program-content{
		font-size:20px;
		.icon{
			.inline-block();
		float:none;
		margin:0 0 15px 0;
		
		}
		.centered();
		}
	.about-item{
		.left{
			margin-bottom:20px;
			}
		}
	.toggler{
		.trigger{
			h5{
				font-size:20px;
				}
			}
		}
	.program-page-item{
		padding:0;
		.title{
			width:100%;
			position:relative;
			top:0;
			left:0;
			.centered();
			height:auto;
			padding:30px;
			}
		.text{
			padding:20px;
			}
		}
	.footer-content{
		padding-bottom:25px;
		.centered();
		.left{
			.centered();
			}
		.left,.right{
			border:none;
			padding:15px;
			}
		}
	#timeline-wrap{
	position:relative;
	#time-bar{
	
		position:absolute;
		left:20px;
		
		}
	.timeline-year.left > .year-item.year-header .connector{left:0!important;}
	.timeline-year{
		max-width:95%;
		>.year-item{
			text-align:right!important;
			.bitter(18px);
			color:#fff;
			position:relative;
			margin:10px 0 10px 0;
			padding-right:10px;
			.connector{
				background:#b4b4b4;
				width:75%!important;
				position:absolute;
				top:50%;
				margin-top:-2px;
				height:4px;
				display:block;
				z-index:10;
				.opacity(0);
				left:0!important;
				transform: translateX(30px)!important;
				}
			.year-dot{
				
					left:5px!important;
					margin-top:-15px;
					margin-left:0!important;
				
					}
			&.year-header{
				padding:0 10% 0 0!important;
				.year-dot{
					left:0!important;
					}
				.number{
					.inline-block();
					width:170px;
					height:170px;
					border:3px solid @aqua;
					.centered();
					color:@yellow;
					background:#fff;
					.rounded-corners(50%);
					.montserrat(40px);
					padding-top:50px;
					}
				}
			.year-item-content{
				
				max-width:80%!important;
			
				transform: translateX(30px)!important;
				.text{
					text-align:left;
					line-height:1.3;
					a{.bold();color:@yellow;&:hover{text-decoration:underline;}}
					p{padding-bottom:5px;}
					}
				}
			
			
			}
		
		}
	}
	#board-wrap{
		.centered();
		}
	#board-wrap > .board-item:nth-child(even) .item-content{float:none!important;}
	.board-item{
		.image-wrap{
			padding:0!important;
			.centered();
			margin-bottom:10px;
			.image{
				width:220px!important;
				height:220px!important;
				padding-bottom:0!important;
				.inline-block();
				transform: translateY(0)!important;
				}
			}
		.item-content{
			width:90%!important;
			float:none!important;
			.inline-block();
			transform:translateX(0)!important;
			span.triangle{
				display:none!important;
				}
			}
		}
    .mobile-center{.centered();}
    .mobile-hide{display:none!important;}
    }
   
@media only screen 
and (max-width : 480px) {



}